<template>
  <div>
    <div>
      <div class="flex items-center mb-5">
        <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
          Enquiry Information
        </h3>
        <hr class="flex-1 border-primary mx-4" />
        <button
          @click="updateProspectFields"
          class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
        >
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
            />
            <path d="M17 21v-8H7v8M7 3v5h8" />
          </svg>
          <span>Update Details</span>
        </button>
      </div>
      <div class="flex flex-wrap -my-3 -mx-5">
        <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
          <label class="uppercase font-semibold text-sm">Enquiry Status</label>
          <div class="inline-block relative">
            <select
              v-model="enquiry.status"
              @change="changeStatus"
              class="bge-input bge-select rounded"
            >
              <option value="Open">Open</option>
              <option value="Closed (Sold)">Closed (Sold)</option>
              <option value="Closed (Lost)">Closed (Lost)</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
          <label class="uppercase font-semibold text-sm">Enquiry Status</label>
          <div class="inline-block relative">
            <select
              v-model="enquiry.status_detail"
              @change="dropdownChanged"
              class="bge-input bge-select rounded"
            >
              <template v-for="option in selectedDetailOptions">
                <option :key="option" :value="option">{{ option }}</option>
              </template>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
          <label class="uppercase font-semibold text-sm">Default Agent</label>
          <div class="inline-block relative">
            <select
              v-model="enquiry.default_agent.name"
              @change="dropdownChanged"
              class="bge-input bge-select rounded">
                <option value="Advance GE">Advance GE</option>
                <option value="Ashford Garage Equipment">Ashford Garage Equipment</option>
                <option value="Autoquip">Autoquip</option>
                <option value="Boston">Boston</option>
                <option value="Boston - KENYA">Boston - KENYA</option>
                <option value="Brain Bee">Brain Bee</option>
                <option value="C &amp; V Lift Services">C &amp; V Lift Services</option>
                <option value="C-Tech">C-Tech</option>
                <option value="CARS">CARS</option>
                <option value="D&amp;H Equipment Services">D&amp;H Equipment Services</option>
                <option value="Down GE">Down GE</option>
                <option value="Euro Tec Equipment">Euro Tec Equipment</option>
                <option value="First Equipment">First Equipment</option>
                <option value="Garage Equip Nationwide">Garage Equip Nationwide</option>
                <option value="GEUK Ltd">GEUK Ltd</option>
                <option value="Hilliers GE">Hilliers GE</option>
                <option value="Hitech GE">Hitech GE</option>
                <option value="KRB">KRB</option>
                <option value="KSA">KSA</option>
                <option value="MAHA UK Ltd">MAHA UK Ltd</option>
                <option value="R A Cox GE">R A Cox GE</option>
                <option value="S &amp; G Precision Engineering">S &amp; G Precision Engineering</option>
                <option value="Saracen Distribution Ltd">Saracen Distribution Ltd</option>
                <option value="SMG Garage Equipment">SMG Garage Equipment</option>
                <option value="SWGE">SWGE</option>
                <option value="Transtec GE">Transtec GE</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-10">
      <div class="flex items-center mb-5">
        <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
          Marketing Information
        </h3>
        <hr class="flex-1 border-primary ml-4" />
      </div>
      <div class="flex flex-wrap -my-3 -mx-5">
        <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
          <label class="uppercase font-semibold text-sm">Contact Date</label>
          <datepicker
            input-class="bge-input bge-input-spacing rounded w-full"
            v-model="enquiry.enquiry_date"
            format="dd/MM/yyyy"
            @change="dropdownChanged"
          ></datepicker>
        </div>
        <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
          <label class="uppercase font-semibold text-sm">Contact Method</label>
          <div class="inline-block relative">
            <select
              v-model="enquiry.enquiry_method"
              @change="dropdownChanged"
              class="bge-input bge-select rounded"
            >
              <option value="Sales Enquiry Form">Sales Enquiry Form</option>
              <option value="Sales Callback Form">Sales Callback Form</option>
              <option value="Direct Email">Direct Email</option>
              <option value="Direct Phone Call (Office)"
                >Direct Phone Call (Office)</option
              >
              <option value="Direct Phone Call (Ross Mobile)"
                >Direct Phone Call (Ross Mobile)</option
              >
              <option value="Walk-In">Walk-In</option>
              <option value="Trade Show">Trade Show</option>
              <option value="Facebook">Facebook</option>
              <option value="Twitter">Twitter</option>
              <option value="LinkedIn">LinkedIn</option>
              <option value="Other">Other</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
          <label class="uppercase font-semibold text-sm">Marketing Source</label>
          <div class="inline-block relative">
            <select
              v-model="enquiry.enquiry_source"
              @change="dropdownChanged"
              class="bge-input bge-select rounded">
              <option value="Unknown">Unknown</option>
              <option value="Existing client">Existing client</option>
              <option value="Internet search">Internet search</option>
              <option value="Google Search">Google Search</option>
              <option value="Bing Search">Bing Search</option>
              <option value="Other Search Engine">Other Search Engine</option>
              <option value="Google Adwords">Google Adwords</option>
              <option value="Magazine">Magazine</option>
              <option value="Agent referral">Agent referral</option>
              <option value="Customer referral">Customer referral</option>
              <option value="Aware of Boston brand">Aware of Boston brand</option>
              <option value="Test Centre referral">Test Centre referral</option>
              <option value="Seen in a garage">Seen in a garage</option>
              <option value="Previously met client">Previously met client</option>
              <option value="Previously quoted">Previously quoted</option>
              <option value="Portman">Portman</option>
              <option value="Facebook">Facebook</option>
              <option value="Twitter">Twitter</option>
              <option value="LinkedIn">LinkedIn</option>
              <option value="Other">Other</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
          <label class="uppercase font-semibold text-sm">Product Enquiry</label>
          <div class="inline-block relative">
            <select v-model="enquiredProduct" @change="dropdownChanged" class="bge-input bge-select rounded">
              <option value="1-Post Lift">1-Post Lift</option>
              <option value="2-Post Lift">2-Post Lift</option>
              <option value="4-Post Lift">4-Post Lift</option>
              <option value="Scissor Lift">Scissor Lift</option>
              <option value="Class 1 &amp; 2 MOT Bay">Class 1 &amp; 2 MOT Bay</option>
              <option value="Class 4 MOT Bay">Class 4 MOT Bay</option>
              <option value="Class 7 MOT Bay">Class 7 MOT Bay</option>
              <option value="C1 &amp; 2 Brake Tester">C1 &amp; 2 Brake Tester</option>
              <option value="C4 Brake Tester">C4 Brake Tester</option>
              <option value="C7 Brake Tester">C7 Brake Tester</option>
              <option value="M/C Roller Brake Tester">M/C Roller Brake Tester</option>
              <option value="Decelerometer">Decelerometer</option>
              <option value="Emissions Equipment">Emissions Equipment</option>
              <option value="Headlamp Aim Tester">Headlamp Aim Tester</option>
              <option value="Play Detector">Play Detector</option>
              <option value="Tyre Changer">Tyre Changer</option>
              <option value="Wheel Balancer">Wheel Balancer</option>
              <option value="Jacking">Jacking</option>
              <option value="Service &amp; Maintenance">Service &amp; Maintenance</option>
              <option value="OPTL Upgrade">OPTL Upgrade</option>
              <option value="GarageWorx">GarageWorx</option>
              <option value="O3-NEX">O3-NEX</option>
              <option value="AC Hydraulic">AC Hydraulic</option>
              <option value="Turning Plates">Turning Plates</option>
              <option value="Jacking Beam">Jacking Beam</option>
              <option value="Connected Equipment">Connected Equipment</option>
              <option value="MCU Upgrade">MCU Upgrade</option>
              <option value="Unknown">Unknown</option>
              <option value="Other - see Notes">Other - See Notes</option>
              <option value="Other">Other</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
          <label class="uppercase font-semibold text-sm">Quoted Price</label>
          <div
            class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
          >
            <div class="flex justify-center items-center p-2">
              <svg class="fill-current h-5 w-5" viewBox="0 0 24 24">
                <path
                  d="M6,21V19C10,17 9.5,13 9.5,13H7V11H9.5C8.5,6.5 10,3 14,3C16,3 17,3.5 17,3.5V5.5C11,3.5 11,8 11.5,11H16V13H11.5C11.5,13 12,17 9.5,19H18V21H6Z"
                />
              </svg>
            </div>
            <input
              v-model="enquiry.price_quoted"
              class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
              type="text"
            />
          </div>
        </div>
        <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
          <label class="uppercase font-semibold text-sm">Sold Price</label>
          <div
            class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
          >
            <div class="flex justify-center items-center p-2">
              <svg class="fill-current h-5 w-5" viewBox="0 0 24 24">
                <path
                  d="M6,21V19C10,17 9.5,13 9.5,13H7V11H9.5C8.5,6.5 10,3 14,3C16,3 17,3.5 17,3.5V5.5C11,3.5 11,8 11.5,11H16V13H11.5C11.5,13 12,17 9.5,19H18V21H6Z"
                />
              </svg>
            </div>
            <input
              v-model="enquiry.price_sold"
              class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
              type="text"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { COUNTRIES } from "@/utils/constants";
import Notification from "@/components/Notification.vue";

export default {
  name: "Information",
  components: {
    Datepicker,
    Notification,
  },
  props: {
    enquiry: Object,
  },
  data() {
    return {
      countries: [],
      selectedDetailOptions: "",
      openStatusDetailOptions: [
        "New",
        "No Successful Contact As Yet",
        "Sent to Agent",
        "Contacted By Boston/Agent - Awaiting Quote",
        "Contacted By Agent - Awaiting Site Visit",
        "Researching Only - Early Stages",
        "Looking For Premises",
        "Awaiting planning Permission for new building / Extension",
        "Premises Currently Being Built / Extended/Altered",
        "Site Inspection booked",
        "Sent Quote",
        "Received Quote Considering",
        "Awaiting Council Approval",
        "Awaiting CAD Plans",
        "Awaiting AIP from DVSA",
        "Awaiting Finance / Kennetts",
        "Finance Agreed Proceeding",
        "Has AIP - Awaiting Ground Works / Install",
        "Considering But Doesn’t Want Chasing",
        "Other See Notes",
      ],
      soldStatusDetailOptions: ["Boston Equipment Sold"],
      lostStatusDetailOptions: [
        "Boston Too Expensive - Bought / Buying Other",
        "Preferred Competitors Equipment - Bought / Buying Other",
        "Slow Response From or Won't Deal With Local Agent",
        "Enquiry For Unsupported Product",
        "Customer Wants / Bought 2nd Hand Equipment",
        "Premises Do Not Meet DVSA Requirements",
        "Our Lift Too Big, Buying Smaller",
        "Repaired Faulty Equipment As Cheaper",
        "Situation Changed - No Longer Interested",
        "Unable To Contact / No Reply From Customer",
        "Unable To Afford / Finance Declined",
        "Other See Notes",
        "Not Viable",
        "Unknown",
      ],
      enquiredProduct: null,
    };
  },
  mounted() {
    this.setStatusDetail();

    this.countries = COUNTRIES;

    if (
      this.enquiry.enquired_products &&
      this.enquiry.enquired_products.length > 0
    ) {
      this.enquiredProduct = this.enquiry.enquired_products[0].name;
    }
  },
  methods: {
    changeStatus() {
      this.dropdownChanged();
      this.setStatusDetail();
      this.enquiry.status_detail = this.selectedDetailOptions[0];
    },
    setStatusDetail() {
      switch (this.enquiry.status) {
        case "Closed (Sold)":
          this.selectedDetailOptions = this.soldStatusDetailOptions;
          break;
        case "Closed (Lost)":
          this.selectedDetailOptions = this.lostStatusDetailOptions;
          break;
        default:
          this.selectedDetailOptions = this.openStatusDetailOptions;
          break;
      }
    },
    dropdownChanged() {
      this.$store.dispatch("setFormDirty");
    },
    async updateProspectFields() {
      let updatedData = {
        status: this.enquiry.status,
        status_detail: this.enquiry.status_detail,
        default_agent: {
          name: this.enquiry.default_agent.name,
        },
        enquiry_date: this.enquiry.enquiry_date,
        enquiry_method: this.enquiry.enquiry_method,
        enquiry_source: this.enquiry.enquiry_source,
        enquired_products: [],
      };

      if (this.enquiredProduct) {
        updatedData.enquired_products.push({
          name: this.enquiredProduct,
        });
      }
      try {
        this.isBusy = true;
        await this.SalesProspectService.updateProspect(
          this.enquiry.prospect_id,
          updatedData
        );

        this.$store.dispatch("setFormClean");
        this.$store.dispatch("clearNavPrevented");
        this.$store.dispatch("setNavPreventedTo", null);

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: "Update Successful",
                  close: onClose,
                },
              },
              `Details for '${this.enquiry.name}' were updated`
            );
          },
          {
            position: "top-right",
          }
        );
      } catch (error) {
        console.error(error);
        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              "Error occurred while updating info, please try again later"
            );
          },
          {
            position: "top-right",
          }
        );
      }

      this.isBusy = false;
    },
  },
};
</script>
